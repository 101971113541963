import toast from "react-hot-toast";
import {gql} from "@apollo/client";
import {client} from "@/ApolloClient";

const query = gql`
  query publicAssetSearch($organizationId: UUID!, $term: String, $locationId: UUID, $assetId: UUID) { 
    publicAssetSearch(organizationId: $organizationId, term: $term, locationId: $locationId, assetId: $assetId) { 
        totalCount
        items
        {
            id
            asset_name
            address_full
            location_id
        
            attributes {
                name
                value
            }
        }
    
    }
 }
`;

export function apiGetAssetSearchExtended(organizationId, term, locationId, assetId) {
    return new Promise(async (resolve, reject) => {
        if(!organizationId) reject("organizationId is required");

        client
            .query({
                query: query,
                variables: {
                    organizationId,
                    term,
                    locationId,
                    assetId
                },
                errorPolicy: "ignore",
                fetchPolicy: "network-only"
            })
            .then((result) => {
                resolve(result?.data?.publicAssetSearch);
            })
            .catch((err) => {
                toast.error("Api Error, Unable to get asset");
                reject(err); // R
            })
    });
}
