
"use client";

import React, {useEffect, useState} from "react";
import {Card, CardBody, Spinner, Typography, CardFooter} from "@material-tailwind/react";
import {apiGetAsset, apiGetAssetImages} from "@/api/asset";
import {EMPTY_GUID} from "@/state/constants";
import {PmStore} from "@/state";
import {useRouter} from "next/navigation";
import placeholder from "../images/asset_placeholder.webp";


export default function AssetCard({assetId}) {
    const state = PmStore();
    const [Asset,SetAsset] = useState({});
    const [AssetImage,SetAssetImage] = useState(null);

    const [Loading, SetLoading] = useState(false);
    let navigate = useRouter();

    function GetAssetDetails(){
        if(!state?.selectedOrganization?.id) return;
        if(!assetId || assetId === EMPTY_GUID) return;
        apiGetAsset(state?.selectedOrganization?.id, assetId)
            .then((data) => {
                SetAsset(data)

                console.log(data);

                let imageUrl = "";
                if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
                    imageUrl = "https://rentalflow.dwai.xyz"

                imageUrl = `${imageUrl}${data?.image?.url}`;
                SetAssetImage(imageUrl);

            })
            .then(() => SetLoading(false))
    }

    useEffect(() => {
        GetAssetDetails();


    }, [state]);


    if(Loading) return(<div className={"flex justify-center h-[400px]"}><Spinner /></div>)



    return (
        <Card shadow={false} className="border  border-gray-300 hover:shadow-lg hover:border-gray-200 hover:cursor-pointer h-full" onClick={() => navigate.push("/asset/"+ assetId)}>
            <CardBody className={"h-full"}>
                {Asset?.image_id != EMPTY_GUID ?
                    <ImageWithLoader src={AssetImage ?? placeholder.src} />
                    :
                    <img className=" w-full" src={placeholder.src} />
                }

                <Typography className="mb-0 text-base text-xl mb-2 !font-semibold !text-gray-500">
                    {Asset?.name ?? "Unknown"} <small>(Type TBD)</small>
                </Typography>

                <Typography className="mb-2" color="blue-gray" variant="h6">
                    {Asset?.address}
                </Typography>
                <Typography className="mb-5 text-base font-normal leading-[27px] !text-gray-500 hidden md:block">
                    {Asset?.description ?? "Unknown"}
                </Typography>

                {/*<div className="flex items-center gap-3">*/}
                {/*    <Typography*/}
                {/*        variant="h6"*/}
                {/*        className="line-through"*/}
                {/*        color="blue-gray"*/}
                {/*    >*/}
                {/*        {price[0]}*/}
                {/*    </Typography>*/}
                {/*    <Typography variant="h5" color="blue-gray">*/}
                {/*        {price[1]}*/}
                {/*    </Typography>*/}
                {/*</div>*/}
            </CardBody>
            <CardFooter>Click for Details</CardFooter>
        </Card>
    );
}


const ImageWithLoader = ({ src, alt, spinnerSize = 50 }) => {
    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <div>
            {loading && (
                <div className={"flex justify-center "}>
                    <Spinner />

                </div>
            )}
            <img
                src={src}
                alt={alt}
                onLoad={handleImageLoad}
                className=" w-full"
            />

        </div>
    );
};
