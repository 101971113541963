import toast from "react-hot-toast";
import {gql} from "@apollo/client";
import {client} from "@/ApolloClient";

const query = gql`
  query assetDetails($organizationId: UUID!, $assetId: UUID!) { 
    assetDetails(organizationId: $organizationId, assetId: $assetId) { 
       attribute_id
       value_bool
       value_id
       value_text
        attribute {
            name
            attribute_type
            attribute_type_id
        }
    }
 }
`;

export function apiGetAssetDetails(organizationId, assetId) {
    return new Promise(async (resolve, reject) => {
        if(!organizationId) reject("organizationId is required");

        client
            .query({
                query: query,
                variables: {
                     organizationId,
                    assetId
                },
                errorPolicy: "ignore",
                fetchPolicy: "network-only"
            })
            .then((result) => {
                resolve(result?.data?.assetDetails);
            })
            .catch((err) => {
                toast.error("Api Error, Unable to get asset");
                reject(err); // R
            })
    });
}
