import toast from "react-hot-toast";
import {gql} from "@apollo/client";
import {client} from "@/ApolloClient";

const query = gql`
  query publicAsset($organizationId: UUID!, $assetId: UUID!) { 
    publicAsset(organizationId: $organizationId, assetId: $assetId) { 
        address
        description
        id
        name
        address_id
        layout_id
        layout_name
        
        location_id
        location_name
        image_id
        image {
            url
        }
        type_id
        type {
            
            name
        }
    }
 }
`;

export function apiGetAsset(organizationId, assetId) {
    return new Promise(async (resolve, reject) => {
        if(!organizationId) reject("organizationId is required");

        client
            .query({
                query: query,
                variables: {
                     organizationId,
                    assetId
                },
                errorPolicy: "ignore",
                fetchPolicy: "network-only"
            })
            .then((result) => {
                resolve(result?.data?.publicAsset);
            })
            .catch((err) => {
                toast.error("Api Error, Unable to get asset");
                reject(err); // R
            })
    });
}
