import toast from "react-hot-toast";
import {gql} from "@apollo/client";
import {client} from "@/ApolloClient";

const query = gql`
  query myAssetSearch(
        $organizationId: UUID!, 
        $term: String, 
        $page: Int,
        $pageSize:Int,
        $assetId: UUID, 
        $locations: [UUID!],
        $types: [UUID!],
        $layouts: [UUID!]
    ) { 
    myAssetSearch(organizationId: $organizationId, term: $term, page: $page, pageSize: $pageSize, assetId: $assetId, locations: $locations, types: $types, layouts: $layouts) { 
        totalCount
        items
        {
            id
            asset_name
            address_full
            location_id
        
        }
    
    }
 }
`;


export function apiMyAssetSearch(organizationId, term, assetId, locations, types, layouts) {
    return new Promise(async (resolve, reject) => {
        if(!organizationId) reject("organizationId is required");

        client
            .query({
                query: query,
                variables: {
                    organizationId,
                    term,
                    locations,
                    assetId,
                    types,
                    layouts
                },
                errorPolicy: "ignore",
                fetchPolicy: "network-only"
            })
            .then((result) => {
                resolve(result?.data?.myAssetSearch);
            })
            .catch((err) => {
                toast.error("Api Error, Unable to get asset");
                reject(err); // R
            })
    });
}
