import toast from "react-hot-toast";
import {gql} from "@apollo/client";
import {client} from "@/ApolloClient";

const query = gql`
  query publicAssetTypes($organizationId: UUID!) { 
    publicAssetTypes(organizationId: $organizationId) { 
        id
        name
    }
 }
`;

export function apiGetAssetTypes(organizationId) {
    return new Promise(async (resolve, reject) => {
        if(!organizationId) reject("organizationId is required");

        client
            .query({
                query: query,
                variables: {
                     organizationId,
                },
                errorPolicy: "ignore",
                fetchPolicy: "network-only"
            })
            .then((result) => {
                resolve(result?.data?.publicAssetTypes);
            })
            .catch((err) => {
                toast.error("Api Error, Unable to get asset types");
                reject(err); // R
            })
    });
}
