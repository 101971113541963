import toast from "react-hot-toast";
import {gql} from "@apollo/client";
import {client} from "@/ApolloClient";

const query = gql`
  query assetImages($organizationId: UUID!, $assetId: UUID!) { 
    assetImages(organizationId: $organizationId, assetId: $assetId) { 
        active
        organization_id
        order
        id
        url
       
    
    }
 }
`;

export function apiGetAssetImages(organizationId, assetId) {
    return new Promise(async (resolve, reject) => {
        if(!organizationId) reject("organizationId is required");

        client
            .query({
                query: query,
                variables: {
                     organizationId,
                    assetId
                },
                errorPolicy: "ignore",
                fetchPolicy: "network-only"
            })
            .then((result) => {
                resolve(result?.data?.assetImages);
            })
            .catch((err) => {
                toast.error("Api Error, Unable to get asset images");
                reject(err); // R
            })
    });
}
